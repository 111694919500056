const handleQuoteButtonClick = () => {
  try {
    // eslint-disable-next-line no-undef
    gtag("event", "conversion", {
      send_to: "AW-631289825/FsO-CLOkhuUBEOHvgq0C",
    });
  } catch (e) {
    // This throws an exception on non-production builds, and it's not really an issue.
    console.log(e);
  }
};

const handleMailingListButtonClick = () => {
  try {
    // eslint-disable-next-line no-undef
    gtag("event", "conversion", {
      send_to: "AW-631289825/cNOOCJ7q9OkBEOHvgq0C",
    });
  } catch (e) {
    // This throws an exception on non-production builds, and it's not really an issue.
    console.log(e);
  }
};

const handleBtnAnalytics = (url) => {
  switch (url) {
    case "https://quote.ecoenergy.global/":
      handleQuoteButtonClick();
      break;
    case "https://forms.zohopublic.com/jeremy50/form/EcoEnergyAitemaadSignup/formperma/bZ3NTvYSWbTN2nqbmjew96uid_3Cj8EYCVUkmKwk8pg":
      handleMailingListButtonClick();
      break;
  }
};

export { handleQuoteButtonClick, handleMailingListButtonClick, handleBtnAnalytics };
