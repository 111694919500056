import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import { handleBtnAnalytics } from "../utils/button";
import appendQueryString from "../utils/query";

const CallToActionButton = ({ link, text, btnClass, size = "medium", target = "__blank" }) => {
  const [linkWithParams, setLinkWithParams] = useState('');

  useEffect(() => {
    if (!link.startsWith("#")) {
      setLinkWithParams(appendQueryString(link));
    }
  }, []);

  if (!link) return <div />
  const btnClassStr = btnClass + " action";
  let linkElem;
  // within page
  if (link.startsWith("#")) {
    linkElem = (
      <Button
        className={btnClassStr}
        onClick={() =>
          document.querySelector(link)?.scrollIntoView({ behavior: "smooth" })
        }
      >
        {text}
      </Button>
    );
  }

  // within app
  else if (link.startsWith("/")) {
    linkElem = (
      <Link to={linkWithParams} target={target}>
        <Button className={btnClassStr}>{text}</Button>
      </Link>
    );
  } else {
    // external link
    linkElem = (
      <a href={linkWithParams} target={target} className={btnClassStr} onClick={() => handleBtnAnalytics(link)}>
        {text}
      </a>
    );
  }

  return <div className={`multi-purpose-link ${size}`}>{linkElem}</div>;
};

CallToActionButton.propTypes = {
  link: PropTypes.string.isRequired, 
  text: PropTypes.string.isRequired, 
  btnClass: PropTypes.string, 
  size: PropTypes.oneOf(["small", "medium"]), 
  target: PropTypes.string,
};

CallToActionButton.defaultProps = {
  size: "medium", 
  target: "__blank",
};

export default CallToActionButton;
